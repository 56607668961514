<template>
  <div
    ref="rootNode"
  >
    <div class="overlay" />
    <div ref="three" />
    <Controls
      ref="controlComponent"
      @key-press="captureKeyPress"
      @mouse-info="captureMouseInfo"
    />
    <Camera ref="camera" />
    <Entity
      ref="entityComponent"
      @add-entity="addEntity"
      @create-rigid-body="createRigidBody"
    />
    <Actions
      ref="actionsComponent"
    />
    <Physics
      v-if="physicsLoaded == true"
      ref="physicsComponent"
      :entities="entities"
      @add-to-scene="addToScene"
    />
    <Terrain
      v-if="terrainLoaded == true"
      ref="terrainComponent"
    />
    <CardGame
      v-if="sceneLoaded == true"
      ref="cardGameComponent"
      @add-to-scene="addToScene"
    />
    <Composer
      v-if="sceneLoaded == true"
      ref="composerComponent"
      :scene-loaded="sceneLoaded"
    />
    <Transitions
      v-if="sceneLoaded == true"
      ref="transitionsComponent"
    />
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { inject, nextTick, onBeforeUnmount, onMounted, provide, ref, toRaw, watch } from 'vue';
//import * as AmmoJS from 'ammo.js';
import * as AmmoJS from 'ammojs3';
import { utils } from '@/utils/utils';
import { gameStore } from "@/stores/game";
import Stats from 'stats.js';
import Entity from './Entity.vue';
import Controls from './Controls.vue';
import Camera from './Camera.vue';
import Physics from './Physics.vue';
import Composer from './Composer.vue';
import Actions from './Actions.vue';
import CardGame from './CardGame.vue';
import backgroundSkybox from '../../public/textures/kyoto-skybox-1.jpg';
import backgroundSkyboxDepth from '../../public/textures/kyoto-skybox-1-depth.jpg';
import Terrain from './Terrain.vue';
import Transitions from './Transitions.vue';

export default {
  name: 'Scene',
  "components": {
      Controls,
      Camera,
      Entity,
      Actions,
      Physics,
      Composer,
      CardGame,
      Terrain,
      Transitions
  },
  setup() {

    const rootNode = ref(),
    axios = inject('Axios'),
    fpsstats = ref(new Stats()),
    memstats = ref(new Stats()),
    deltaX = ref(0),
    deltaY = ref(0),
    currentX = ref(0),
    currentY = ref(0),
    keyPress = ref({}),
    ammoLib = ref({}),
    sunDistance = ref(10000),
    sunGeom = new THREE.SphereGeometry( 100, 32, 16 ),
    sunMat = new THREE.MeshBasicMaterial( { color: 0xffffff } ),
    sun = new THREE.Mesh( sunGeom, sunMat ),
    sunAngle = ref(0),
    debounceKey = ref({}),
    mouseInfo = ref({
      clientX:0,
      clientY:0,
      deltaX:0,
      deltaY:0,
      dragDeltaX:0,
      dragDeltaY:0,
      mouseDown:false,
      mouseUp:false,
      buttons:0
    }),
    game = gameStore(),
    scene = new THREE.Scene(),
    // renderer = new THREE.WebGLRenderer( { alpha: true } ),
    renderer = new THREE.WebGLRenderer( { } ),
    clock = new THREE.Clock(),
    axesHelper = new THREE.AxesHelper( 5 ),
    gridHelper = new THREE.GridHelper( 15, 15),
    time = ref(0),
    three = ref(null),
    camera = ref(null),
    terrainComponent = ref(null),
    cardGameComponent = ref(null),
    controlComponent = ref(null),
    entityComponent = ref(null),
    actionsComponent = ref(null),
    physicsComponent = ref(null),
    composerComponent = ref(null),
    transitionsComponent = ref(null),
    physicsLoaded = ref(false),
    entities = ref({}),
    cursor = ref(),
    sceneLoaded = ref(false),
    terrainLoaded = ref(false),
    intersected = ref(),
    createCursor = () => {

      const cursorGeometry = new THREE.BufferGeometry(),
       vertices = new Float32Array([
        -0.1, 0, 0,
        0.1, 0, 0,
        0, -0.1, 0,
        0, 0.1, 0
      ]);
      cursorGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

      const cursorMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

      cursor.value = new THREE.Mesh(cursorGeometry, cursorMaterial);
      scene.add(toRaw(cursor.value));

    },
    loadEntities = () => {

      axios.get(

          // `${process.env.VUE_APP_SERVER_URI}public/magicMenhir.json`,
          `${process.env.VUE_APP_SERVER_URI}public/zoi.json`,
          {

              "headers": {
              }

          }

      ).
          then((response) => {

              if (response.status === 200) {

                  const n = response.data;
                  entityComponent.value.loadModel(n.model.entities[0],0);

              }

          }).
          catch((error) => {
          });

    },
    createRigidBody = (node) => {

      physicsComponent.value.createRigidBody(node.node, node.shape, node.mass, node.inertia, node.kinematic);

    },
    captureKeyPress = (o) => {

      keyPress.value = o.value;

    },
    captureMouseInfo = (o) => {

      mouseInfo.value = o.value;

/*
      for (var i in o.value) {

          mouseInfo.value[i] = o.value[i];
      }
      mouseInfo.value.buttons = o.value.buttons;
*/

    },
    initScene = () => {

      console.log('INIT SCENE')

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);

      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap; 

      three.value.appendChild(renderer.domElement);
      scene.add(axesHelper);
      scene.add(gridHelper);

      const geometry = new THREE.BufferGeometry(),
        textureLoader = new THREE.TextureLoader(),
        loader = new GLTFLoader()
            .setMeshoptDecoder(MeshoptDecoder);

				const panoSphereGeo = new THREE.SphereGeometry( 11000, 64,  64);

				// Create the panoramic sphere material
				const panoSphereMat = new THREE.MeshStandardMaterial( {
					side: THREE.BackSide,
					displacementScale: - 100.0,
          color: new THREE.Color(10, 10, 10)
				});

				// Create the panoramic sphere mesh
				const sphere = new THREE.Mesh( panoSphereGeo, panoSphereMat );

				textureLoader.load( backgroundSkybox, function ( texture ) {

					texture.colorSpace = THREE.SRGBColorSpace;
					texture.minFilter = THREE.NearestFilter;
					texture.generateMipmaps = false;
					sphere.material.map = texture;

				});

				textureLoader.load( backgroundSkyboxDepth, function ( depth ) {

					depth.minFilter = THREE.NearestFilter;
					depth.generateMipmaps = false;
					sphere.material.displacementMap = depth;

          scene.add(sphere);

				});

      // Lighting
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.25);
      scene.add(ambientLight);

      sun.name = "sun";
      sun.position.set(sunDistance.value, sunDistance.value, -sunDistance.value);

      const sunLight = new THREE.DirectionalLight(0xffffff, 0.9);
      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = 1024;
      sunLight.shadow.mapSize.height = 1024;
      sunLight.shadow.camera.near = 0.5;
      sunLight.shadow.camera.far = 5000;
      sunLight.shadow.bias = -0.0001; 

/*
      const sunLight = new THREE.PointLight(0xffffff, 100000, 1000, 2);
      sunLight.castShadow = true;
      sunLight.shadow.mapSize.width = 1024;
      sunLight.shadow.mapSize.height = 1024;
*/

      sun.add(sunLight);
      scene.add(sun);

      // debug helper
      // scene.add(new THREE.CameraHelper(sunLight));

      // can only be loaded after the renderer is setup

      sceneLoaded.value = true;
      console.log('init scene end')

      setTimeout(()=> {

          terrainLoaded.value = true;

      }, 5000);

    },
    addEntity = (entity) => {

       scene.add(entity.node);
       entities.value[entity.name] = entity;

    },
    addToScene = (e) => {

      switch (e.type) {

        case "box":
          if (!entities.value[e.name]) {

            entities.value[e.name] = {}

          }
          scene.add(e.node);
          break;

      }

    },
    removedRigidBody = (e) => {
    },
    animate = function (delta) {

        // start stats
        fpsstats.value.begin();
        memstats.value.begin();

        // animations
        for (const i in entities.value) {

            if (entities.value[i].animationMixer) {

               entities.value[i].animationMixer.update(delta);

            }

        }

        // camera
        camera.value.process();

        // action
        actionsComponent.value.process();

        // process transitions
        transitionsComponent.value.process();

        // terrain
        if (terrainLoaded.value == true) {

            terrainComponent.value.process();

        }

        // Update the sun's position in a circular orbit
        sunAngle.value += 0.001; // Rotation speed (adjust as needed)

/*
        sun.position.set(
            0,
            Math.sin(sunAngle.value) * sunDistance.value,
            Math.cos(sunAngle.value) * sunDistance.value 
        );
*/

        // render
        renderer.render(scene, camera.value.camera);

        // composer
        toRaw(composerComponent.value.composer).render();

        // end stats
        fpsstats.value.end();
        memstats.value.end();

        // repeat
        requestAnimationFrame( () => { animate(clock.getDelta()) });

    },
    rayCastPointer = () => {

        const cam = camera.value.get(),
          mousePos = new THREE.Vector3(mouseInfo.value.clientX, mouseInfo.value.clientY, 0.5),
          forward = new THREE.Vector3(0, 0, 1);

        const raycaster = new THREE.Raycaster(),
          pointer = new THREE.Vector2();

        pointer.x = ( mouseInfo.value.clientX / window.innerWidth ) * 2 - 1;
        pointer.y = - ( mouseInfo.value.clientY / window.innerHeight ) * 2 + 1;

        raycaster.setFromCamera(pointer, cam);

        const intersects = raycaster.intersectObjects(scene.children
          .filter(object => (object !== gridHelper && object !== axesHelper))); 

        if (intersects.length == 0)
          return;

        const intersect = filterIntersects(intersects);

        if (intersect) {

            const intersectionPoint = intersect.point,
              node = intersect.object,
              vector = intersectionPoint.project(cam),
              x = (vector.x + 1) / 2 * window.innerWidth,
              y = -(vector.y - 1) / 2 * window.innerHeight,
              div = document.createElement('div');

            // Create a data object of the click interaction
            const obj = {
               node,
               vector,
               x,y,
               div
            }

            return obj;

        } else {

            return;

        }

    },
    /*
     * Here we filter for visual model objects.
     * TODO : expand this for selectable physics, armature and whatnot
     */
    filterIntersects = (intersects) => {

        for (let i=0; i < intersects.length; i++) {

            const node = intersects[i].object;

            if ((node.type == "Mesh" || node.type == "SkinnedMesh")
              && node.visible == true) {

                return intersects[i];

            }

        }

    },
    getRootNode = (node, callback) => {

        if (node?.parent?.parent == null) {

            callback(node);

        } else if (node.parent) {

            getRootNode(node.parent, callback)

        } else {

            callback();

        }

    },
    addStats = () => {

        // Show FPS panel (panel 0)
        fpsstats.value.showPanel(0);

        // Create a container for FPS stats
        const fpsContainer = document.createElement('div');
        fpsContainer.style.position = 'absolute';
        fpsContainer.style.top = '0px';
        fpsContainer.style.left = '0px';
        three.value.appendChild(fpsContainer);
        fpsContainer.appendChild(fpsstats.value.dom);

/*
        // Show Memory panel (panel 2)
        memstats.value.showPanel(2);

        // Create a container for Memory stats
        const memContainer = document.createElement('div');
        memContainer.style.position = 'absolute';
        memContainer.style.top = '0px';
        memContainer.style.left = '80px'; // Position next to FPS stats
        three.value.appendChild(memContainer);
        memContainer.appendChild(memstats.value.dom);
*/
    },
    handleResize = () => {

        // Update camera aspect ratio and renderer size on window resize
        //camera.value.update(width.value, height.value);
        //renderer.setSize(width.value, height.value);
 				//composerComponent.value.composer.setSize(width.value, height.value);

        camera.value.camera.aspect = window.innerWidth / window.innerHeight;
				camera.value.camera.updateProjectionMatrix();
				renderer.setSize( window.innerWidth, window.innerHeight );

	// composerComponent.value.composer.setSize(window.innerWidth, window.innerHeight);

    };

    onMounted(() => {

        initScene();

        setTimeout(()=> {

            animate(1);
            physicsComponent.value.render();

            // add camera to scene (optional, used if you want to attach objects to the scene)
            scene.add(camera.value.camera);

            camera.value.camera.position.set(0,0,20);


        }, 1000);

        createCursor();

        addStats();

        window.addEventListener('resize', handleResize);

        watch(
            () => keyPress.value,

            (first, second) => {

                if (keyPress.value.m == true) {

                    loadEntities();

                }

                if (keyPress.value['1'] == true) {

                    entityComponent.value.createSphere({x:0.5,y:15,z:15},{x:0,y:10,z:5});

                }

                if (keyPress.value['2'] == true) {

                    entityComponent.value.createBox({x:1,y:1,z:1},{x:0,y:10,z:5});
                
                }

                if (keyPress.value['0'] == true) {

                    actionsComponent.value.pistol(entities.value.cyberGirl, 'pistol');

                }

                if (keyPress.value['9'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[1]).play();

                }

                if (keyPress.value['8'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[2]).play();

                }

                if (keyPress.value['7'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[3]).play();

                }

                if (keyPress.value['6'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[4]).play();

                }

                if (keyPress.value['5'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[5]).play();

                }

                if (keyPress.value['4'] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[6]).play();

                }

                if (keyPress.value['['] == true) {

                    const act = entities.value.cyberGirl;
                    act.animationMixer.clipAction(act.animations[1]).play();

                }

                if (keyPress.value[']'] == true) {

                    const act = entities.value.cyberGirl;
                    for (const i in act.animations) {

                        act.animationMixer.clipAction(act.animations[i]).reset();

                    }

                }

                if (keyPress.value.p == true) {

                    physicsComponent.value.togglePhysicsVisibility();

                }

                if (keyPress.value.c == true) {

                    const cam = camera.value.get(),
                     ray = rayCastPointer(),
                     target = new THREE.Vector3(0, 0, 1).unproject(cam);

                    if (!target) {

                      target.copy(new THREE.Vector3(0, 0, -1))

                    }

                    const fireVec = target.clone().sub(cam.position).normalize();

                    entityComponent.value.createSphere({x:0.1,y:10,z:10}, cam.position, null, fireVec.multiplyScalar(10));

                }

             //   console.log("renderer.info")
             //   console.log(renderer.info)
             //   console.log(renderer.state)
             //   console.log(renderer)

            },
            { deep: true }

        );

        watch(

            () => mouseInfo.value,

            (first, second) => {

                camera.value.orient();

/*
                if (mouseInfo.value.deltaX != 0 || mouseInfo.value.deltaY != 0) {

                    let selected = game.getState("SELECTED");

                    if (debounceKey['mouseMove'] != true) {

                        debounceKey['mouseMove'] = true;

                        const obj = rayCastPointer();

                        if (obj) {

                            game.setHover(obj);

                        }

                        // actionsComponent.value.hover(obj);

                        setTimeout(()=> {

                            debounceKey['mouseMove'] = false;

                        }, 1000);

                    }

                }

                if (mouseInfo.value.buttons == 1 && mouseInfo.value.mouseDown == true) {

                    if (debounceKey['ray'] != true) {

                        debounceKey['ray'] = true;

                        const obj = rayCastPointer();

console.log("obj ray caset")
console.log(obj)

                        if (obj) {

                            game.addSelected(obj)

                            actionsComponent.value.selected(obj);

                            var opts = {
                              edgeStrength: 3.0,
                              edgeGlow: 0.0,
                              edgeThickness: 2.0,
                              pulsePeriod: 0,
                              rotate: false,
                              usePatternTexture: false
                            };

                            // something broken here 
                            // composerComponent.value.addOutline(obj, opts);

                        } else {

                            game.remSelected(0, 1);

                        }

                    }

                    setTimeout(()=> {

                        debounceKey['ray'] = false;

                    }, 50);

                }
*/
            },
            { deep: true }

        );

    });

    provide('THREE', THREE);
    provide('scene', scene);
    provide('keyPress', keyPress);
    provide('mouseInfo', mouseInfo);
    provide('renderer', renderer);
    provide('camera', camera);
    provide('Ammo', ammoLib);
    provide('utils', utils);

    onBeforeUnmount(() => {

        // Remove event listener and clean up resources
        window.removeEventListener('resize', handleResize);
        renderer.dispose();

    });

    AmmoJS().then((AmmoLib) => {

        ammoLib.value = AmmoLib;
        //console.log('physicsLoaded')
        //console.log('ammoLib.value')
        physicsLoaded.value = true;

    });

    return {
      rootNode,
      sun,
      sunAngle,
      three,
      keyPress,
      axesHelper,
      gridHelper,
      captureMouseInfo,
      captureKeyPress,
      currentX,
      currentY,
      deltaX,
      deltaY,
      initScene,
      scene,
      axios,
      fpsstats,
      memstats,
      loadEntities,
      addEntity,
      handleResize,
      camera,
      entityComponent,
      actionsComponent,
      physicsComponent,
      composerComponent,
      transitionsComponent,
      createRigidBody,
      filterIntersects,
      getRootNode,
      addToScene,
      sceneLoaded,
      physicsLoaded,
      ammoLib,
      renderer,
      cursor,
      entities,
      animate,
      cardGameComponent,
      terrainComponent,
      terrainLoaded,
      intersected,
      rayCastPointer,
      game,
      debounceKey,
      addStats,
      sunDistance
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

#threeContainer {
  width: 100%;
  height: 100vh; /* Adjust as needed */
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
canvas {
    user-select: none; /* Prevents text selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
    pointer-events: all; /* Ensures the canvas gets all pointer events */
}
</style>
