
// this is ridiculous, but it seems impossible to get shadows working without using an existing material.
// threejs should not tie the shadow framework into the material framework.

export function build(material, uniforms) {

    material.onBeforeCompile = (shader) => {

        shader.uniforms.normalMap = uniforms.normalMap;
        shader.uniforms.diffuseMaps = uniforms.diffuseMaps;
        shader.uniforms.normalMaps = uniforms.normalMaps;
        shader.uniforms.roughnessMaps = uniforms.roughnessMaps;
        shader.uniforms.aoMaps = uniforms.aoMaps;
        shader.uniforms.sunPosition = uniforms.sunPosition;
        shader.uniforms.viewDirection = uniforms.viewDirection;

        // outside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <common>`,
          `
          #include <common>

          // these need to be added if normalmap texture is not provided
          attribute vec3 tangent; 
          attribute vec3 bitangent;
          attribute vec2 uv2;

          //varying vec3 vtWorldPosition;
          varying vec3 vWorldNormal;
          varying vec3 vWorldPosition;
          varying vec2 vUv;
          varying vec2 vUv2;
          varying mat3 vTBN;

          varying float vTerrainHeight;

          `
        );

        // inside main
        shader.vertexShader = shader.vertexShader.replace(
          `#include <project_vertex>`,
          `
          #include <project_vertex>

          vUv = uv; // covers segment, typically used for repeating textures.
          vUv2 = uv2; // covers full grid, splatmap etc covers segment.
          vTerrainHeight = 40.0;

          vec3 N = normalize(vec3(modelMatrix * vec4(normal, 0.0)));
          vec3 T = normalize(vec3(modelMatrix * vec4(tangent, 0.0)));
          vec3 B = normalize(vec3(modelMatrix * vec4(bitangent, 0.0)));

          vTBN = mat3(T, B, N); // Now it's in world space

          `
        );

        // set positions
        shader.vertexShader = shader.vertexShader.replace(
          `#include <worldpos_vertex>`,
          `
          #include <worldpos_vertex>
          vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
          `
        );

        shader.vertexShader = shader.vertexShader.replace(
          `#include <defaultnormal_vertex>`,
          `
          #include <defaultnormal_vertex>
          vWorldNormal = normalize(vec3(modelMatrix * vec4(normal, 0.0)));
          // vNormal = normalize(mat3(normalMatrix) * normal);
          `
        );

        // outside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <common>`,
          `
          #include <common>

          uniform mat3 normalMatrix;
          uniform mat4 modelMatrix;
          uniform sampler2D normalMap;
          //uniform vec2 normalScale;

          varying vec2 vUv;
          varying vec2 vUv2;
          varying vec3 vWorldPosition;
          varying vec3 vWorldNormal;
          varying mat3 vTBN;
          varying float vTerrainHeight;

          uniform vec3 sunPosition;
          uniform vec3 viewDirection;
          uniform sampler2DArray diffuseMaps;
          uniform sampler2DArray normalMaps;
          uniform sampler2DArray roughnessMaps;
          uniform sampler2DArray aoMaps;

          float sum( vec3 v ) { return v.x+v.y+v.z; }

          float hash(vec2 n) {
              return fract(sin(dot(n.x,n.y)));
              //return fract(dot(n.x,n.y));
          }

          float valueNoise(vec2 uv) {
              vec2 p0 = floor(uv);
              vec2 p1 = p0 + vec2(1.0, 0.0);
              vec2 p2 = p0 + vec2(0.0, 1.0);
              vec2 p3 = p0 + vec2(1.0, 1.0);

              float v0 = fract(sin(p0.x + p0.y * 57.0) * 43758.5453);
              float v1 = fract(sin(p1.x + p1.y * 57.0) * 43758.5453);
              float v2 = fract(sin(p2.x + p2.y * 57.0) * 43758.5453);
              float v3 = fract(sin(p3.x + p3.y * 57.0) * 43758.5453);

              float tx = uv.x - p0.x;
              float ty = uv.y - p0.y;
              float v0_v1 = mix(v0, v1, tx);
              float v2_v3 = mix(v2, v3, tx);
              return mix(v0_v1, v2_v3, ty);
          }


vec4 hash4( vec2 p ) { return fract(sin(vec4( 1.0+dot(p,vec2(37.0,17.0)),
                                              2.0+dot(p,vec2(11.0,47.0)),
                                              3.0+dot(p,vec2(41.0,29.0)),
                                              4.0+dot(p,vec2(23.0,31.0))))*103.0); }


          // function to randomize textures to prevent tiling effect, based off
          // https://iquilezles.org/articles/texturerepetition/
          //
          // This only works well for repeating textures
          vec4 textureNoTileArray(sampler2DArray texArr, in vec2 uv, int layer) {
              // Sample variation pattern (cache-friendly lookup)

              float k = valueNoise(uv);

              vec3 pxlColor = texture(texArr, vec3(uv, layer)).xyz;

              vec2 duvdx = dFdx( uv );
              vec2 duvdy = dFdy( uv );
              
              float l = k*8.0;
              float f = fract(l);
              
              float ia = floor(l);
              float ib = ia + 1.0;

              vec2 offa = sin(vec2(3.0,7.0)*ia); // can replace with any other hash
              vec2 offb = sin(vec2(3.0,7.0)*ib); // can replace with any other hash

              vec4 cola = textureGrad( texArr, vec3(uv + offa, layer), duvdx, duvdy );
              vec4 colb = textureGrad( texArr, vec3(uv + offb, layer), duvdx, duvdy );

              return mix( cola, colb, smoothstep(0.2,0.8,f-0.1 * sum(cola.xyz-colb.xyz)) );

/*
              // Compute derivatives for mipmapping
              vec2 dx = dFdx(uv), dy = dFdy(uv);

              // texture hash
              //vec2 perturb = murmurHash22(uv * 10.0) * 0.05;

              // Sample the two closest virtual patterns

              vec4 cola = textureGrad(texArr, vec3(uv + offa, layer), dx, dy);
              vec4 colb = textureGrad(texArr, vec3(uv + offb, layer), dx, dy);
              //vec4 cola = texture(texArr, vec3(uv + offa, layer));
              //vec4 colb = texture(texArr, vec3(uv + offb, layer));

              // Interpolate between the two virtual patterns
              return mix(cola, colb, smoothstep(0.2, 0.8, f - 0.1 * dot(cola.rgb - colb.rgb, vec3(1.0))));
              */
          }

          float getSlopeFactor(vec3 normal) {

              return 1.0 - abs(dot(normal, vec3(0.0, 1.0, 0.0)));

          }

          // testing
          vec4 textureNoTile(sampler2DArray texArr, in vec2 uv, int layer) {
              
              vec2 p = floor( uv );
              vec2 f = fract( uv );

              // derivatives (for correct mipmapping)
              vec2 ddx = dFdx( uv );
              vec2 ddy = dFdy( uv );

              // voronoi contribution
              vec4 va = vec4( 0.0 );
              float wt = 0.0;
              for( int j=-1; j<=1; j++ )
              for( int i=-1; i<=1; i++ )
              {
                  vec2 g = vec2( float(i), float(j) );
                  vec4 o = hash4( p + g );
                  vec2 r = g - f + o.xy;
                  float d = dot(r,r);
                  float w = exp(-5.0*d );
                  vec2 d_uv_dx = dFdx(uv); // Derivative in x direction
                  vec2 d_uv_dy = dFdy(uv); // Derivative in y direction
                  vec4 c = textureGrad(texArr, vec3(uv, layer), d_uv_dx, d_uv_dy);
                  va += w*c;
                  wt += w;
              }

              // normalization
              return va/wt;
          }

          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <map_fragment>`,
          `
          #include <map_fragment>

          //#include <normal_pars_fragment>
          //#include <normalmap_pars_fragment>

          float shadowPower = 1.0;
          vec3 shadowColor = vec3(0, 0, 0);
          vec3 grassColor = vec3(0.55, 0.97, 0.09);
          vec3 rockColor = vec3(0.8, 0.8, 0.78);

          // "public/textures/grassplainspec.png",
          // "public/textures/gravel_roughness_1k.jpg",
          // "public/textures/moss_roughness_1k.jpg",
          // "public/textures/rockydirt_roughness_1k.jpg",
          // "public/textures/boulder_roughness_1k.jpg",
          // "public/textures/macrorock-6spec.png"

          // extract diffuse maps
          vec4 grassDiffuse = texture(diffuseMaps, vec3(vUv, 0));
          //vec4 grassDiffuse = textureNoTileArray(diffuseMaps, vUv, 0);
          vec4 gravelDiffuse = texture(diffuseMaps, vec3(vUv, 1));
          vec4 dirtDiffuse = texture(diffuseMaps, vec3(vUv, 3));
          vec4 rockDiffuse = texture(diffuseMaps, vec3(vUv, 4));
          //vec4 shearRockDiffuse = textureNoTileArray(diffuseMaps, vUv, 5);
          vec4 shearRockDiffuse = texture(diffuseMaps, vec3(vUv, 5));

          // extract normal maps
          vec4 grassNormal = texture(normalMaps, vec3(vUv, 0));
          vec4 gravelNormal = texture(normalMaps, vec3(vUv, 1));
          vec4 dirtNormal = texture(normalMaps, vec3(vUv, 3));
          vec4 rockNormal = texture(normalMaps, vec3(vUv, 4));
          //vec4 shearRockNormal = textureNoTileArray(normalMaps, vUv, 5);
          vec4 shearRockNormal = texture(normalMaps, vec3(vUv, 5));

          // extract roughness maps
          //vec4 grassRoughness = textureNoTileArray(roughnessMaps, vUv, 0);
          vec4 grassRoughness = texture(roughnessMaps, vec3(vUv, 0));
          vec4 gravelRoughness = texture(roughnessMaps, vec3(vUv, 1));
          vec4 dirtRoughness = texture(roughnessMaps, vec3(vUv, 3));
          vec4 rockRoughness = texture(roughnessMaps, vec3(vUv, 4));
          //vec4 shearRockRoughness = textureNoTileArray(normalMaps, vUv, 5);
          vec4 shearRockRoughness = texture(roughnessMaps, vec3(vUv, 5));

          // extract ao maps
          //vec4 grassAo = textureNoTileArray(aoMaps, vUv, 0);
          vec4 grassAo = texture(aoMaps, vec3(vUv, 0));
          vec4 gravelAo = texture(aoMaps, vec3(vUv, 1));
          vec4 dirtAo = texture(aoMaps, vec3(vUv, 3));
          vec4 rockAo = texture(aoMaps, vec3(vUv, 4));
          //vec4 shearRockAo = textureNoTileArray(aoMaps, vUv, 5);
          vec4 shearRockAo = texture(aoMaps, vec3(vUv, 5));

          // stylyze effects
          // grassDiffuse.rgb *= grassColor;

          // TODO NORMALIZE ?
          float slope = getSlopeFactor(normalize(vWorldNormal));
          
          // distance and height factors
          float distance = length(vWorldPosition.xyz - cameraPosition);
          float heightFactor = vWorldPosition.y / vTerrainHeight; // Normalize height from 0 to 1
          //float heightFactor = 1.0;

          // Dirt is strong at low height, fades as you go up
          float dirtFactor = smoothstep(1.0, 0.2, heightFactor) * smoothstep(5.0, 0.0, distance);

          // Rock is strong at high height, fades as you go down
          float rockFactor = smoothstep(0.2, 1.0, heightFactor) * smoothstep(5.0, 0.0, distance);

          // TODO, this needs to be in the valleys, particularly runoff frrom mountains

          // Slope factors

          //float grassFactor = smoothstep(0.0, 0.2, slope * (-vWorldPosition.y/(vTerrainHeight*10.0)));
          float grassFactor = smoothstep(0.0, 0.2, slope);
          float gravelFactor = smoothstep(0.20, 0.25, slope);
          float shearRockFactor = smoothstep(0.20, 0.3, slope);

          // Normal maps converted to -1 to 1 range
          vec3 grassNormalVec = (grassNormal.rgb * 2.0 - 1.0);
          vec3 rockNormalVec = rockNormal.rgb * 2.0 - 1.0;
          vec3 gravelNormalVec = gravelNormal.rgb * 2.0 - 1.0;
          vec3 dirtNormalVec = (dirtNormal.rgb * 2.0 - 1.0);
          vec3 shearRockNormalVec = shearRockNormal.rgb * 2.0 - 1.0;

          // Combine normals based on factors
          vec3 materialNormal = normalize(
              grassNormalVec * grassFactor +
              rockNormalVec * rockFactor +
              gravelNormalVec * gravelFactor +
              dirtNormalVec * dirtFactor +
              shearRockNormalVec * shearRockFactor
          );

          // diffuse
          vec4 diffuse = mix(grassDiffuse, dirtDiffuse, dirtFactor);
          diffuse = mix(diffuse, gravelDiffuse, gravelFactor);
          diffuse = mix(diffuse, rockDiffuse, rockFactor);
          diffuse = mix(diffuse, shearRockDiffuse, shearRockFactor);

          vec4 roughness4 = mix(grassRoughness, dirtRoughness, dirtFactor);
          roughness4 = mix(roughness4, gravelRoughness, gravelFactor);
          roughness4 = mix(roughness4, rockRoughness, rockFactor);
          roughness4 = mix(roughness4, shearRockRoughness, shearRockFactor);
          
          // ao
          vec4 ao = mix(grassAo, dirtAo, dirtFactor);
          ao = mix(ao, gravelAo, gravelFactor);
          ao = mix(ao, shearRockAo, shearRockFactor);

          diffuseColor = diffuse;

          // #include <dithering_fragment>
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <roughnessmap_fragment>`,
          `
          float roughnessFactor = roughness4.g * roughness;

          #ifdef USE_ROUGHNESSMAP

            vec4 texelRoughness = texture2D( roughnessMap, vRoughnessMapUv );

            // reads channel G, compatible with a combined OcclusionRoughnessMetallic (RGB) texture
            // roughnessFactor *= texelRoughness.g;
            roughnessFactor = mix(roughnessFactor, texelRoughness.g, 0.5);

          #endif
          `
        );

        // inside main
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_begin>`,
          `

          vec3 forwardVector = normalize(viewDirection);
          vec3 lightDirection = normalize(-sunPosition);
          //vec3 lightDirection = vec3(0.0,0.0,0.0)

          //float angle = dot(forwardVector, lightDirection);
          //angle = (angle * 0.5) + 0.5;

          vec3 normalTex = texture2D(normalMap, vUv).rgb * 2.0 - 1.0;

          mat3 viewModelMatrix = mat3(viewMatrix) * mat3(modelMatrix);
          mat3 TBN = viewModelMatrix * vTBN; // Convert TBN to view space

          normal = normalize(TBN * normalTex);
          normal *= -1.0;

          // float materialFactor = smoothstep(10.0, 0.0, distance * (-vWorldPosition.y/vTerrainHeight));
          float materialFactor = smoothstep(10.0, 0.0, distance);

          // materialNormal = normalize(viewModelMatrix * materialNormal) * materialFactor;
          materialNormal = normalize(viewModelMatrix * materialNormal);
          materialNormal *= -1.0;

          normal = normalize(mix(normal, materialNormal, materialFactor));
          // normal = materialNormal;
          
          // vec3 fn = normal + materialNormal * angle * 10.0;
          // normal += mix(normal, fn, angle * angle);

          #include <lights_fragment_begin>

/*
          // calculate emissive light for stylized effect based on roughness map
          float emissiveFactor = 1.0 - roughnessFactor;

          // TODO: should also be multiplied by celestial color
          vec3 rockEmissive = 0.5 * rockColor * rockFactor;
          vec3 rockIridescence = 0.1 * rockColor * rockFactor;
          float rockFresnel = pow(1.0 - dot(forwardVector, vWorldNormal), 2.0);

          vec3 grassEmissive = 0.5 * grassColor * grassFactor;
          vec3 grassIridescence = 0.2 * grassColor * grassFactor;
          float grassFresnel = pow(1.0 - dot(forwardVector, vWorldNormal), 3.0);
*/

//        vec3 iridescence = (grassFresnel * grassIridescence) + (rockFresnel * rockIridescence);
//        vec3 iridescence = mix(grassIridescence * grassFresnel, rockIridescence * rockFresnel, rockFactor);

//        vec3 emissive = emissiveFactor * (grassEmissive + rockEmissive);

          `
        );

      /*
        shader.fragmentShader = shader.fragmentShader.replace(
          `#include <lights_fragment_end>`,
          `
          // Add the iridescence effect to the final lighting
          // reflectedLight.directDiffuse = clamp(reflectedLight.directDiffuse + reflectedLight.directDiffuse * emissive*iridescence, 0.0, 1.0);
          // reflectedLight.directDiffuse += clamp(iridescence, 0.0, 1.0);

          #include <lights_fragment_end>
          `
        );
*/
    return material;
}
/*
        shader.vertexShader = shader.vertexShader.replace(
            `#include <uv_pars_vertex>`,
            `varying vec2 vUv;
             out vec2 vUvL;
             out vec3 vNormal;
             uniform float uTime;`
        );
*/

};
